@font-face {
  font-family: "poppins_light";
  src: url("../../../fonts/poppins_light.otf") format("opentype");
}
@font-face {
  font-family: "poppins_medium";
  src: url("../../../fonts/poppins_medium.otf") format("opentype");
}
@font-face {
  font-family: "poppins_semibold";
  src: url("../../../fonts/poppins_semibold.otf") format("opentype");
}
.fs-10 {
  font-size: 0.625rem;
}

@media screen and (min-width: 768px) {
  .fs-md-10 {
    font-size: 0.625rem;
  }
}

.fw-10 {
  font-weight: 100 !important;
}

.fs-11 {
  font-size: 0.6875rem;
}

@media screen and (min-width: 768px) {
  .fs-md-11 {
    font-size: 0.6875rem;
  }
}

.fw-11 {
  font-weight: 110 !important;
}

.fs-12 {
  font-size: 0.75rem;
}

@media screen and (min-width: 768px) {
  .fs-md-12 {
    font-size: 0.75rem;
  }
}

.fw-12 {
  font-weight: 120 !important;
}

.fs-13 {
  font-size: 0.8125rem;
}

@media screen and (min-width: 768px) {
  .fs-md-13 {
    font-size: 0.8125rem;
  }
}

.fw-13 {
  font-weight: 130 !important;
}

.fs-14 {
  font-size: 0.875rem;
}

@media screen and (min-width: 768px) {
  .fs-md-14 {
    font-size: 0.875rem;
  }
}

.fw-14 {
  font-weight: 140 !important;
}

.fs-15 {
  font-size: 0.9375rem;
}

@media screen and (min-width: 768px) {
  .fs-md-15 {
    font-size: 0.9375rem;
  }
}

.fw-15 {
  font-weight: 150 !important;
}

.fs-16 {
  font-size: 1rem;
}

@media screen and (min-width: 768px) {
  .fs-md-16 {
    font-size: 1rem;
  }
}

.fw-16 {
  font-weight: 160 !important;
}

.fs-17 {
  font-size: 1.0625rem;
}

@media screen and (min-width: 768px) {
  .fs-md-17 {
    font-size: 1.0625rem;
  }
}

.fw-17 {
  font-weight: 170 !important;
}

.fs-18 {
  font-size: 1.125rem;
}

@media screen and (min-width: 768px) {
  .fs-md-18 {
    font-size: 1.125rem;
  }
}

.fw-18 {
  font-weight: 180 !important;
}

.fs-20 {
  font-size: 1.25rem;
}

@media screen and (min-width: 768px) {
  .fs-md-20 {
    font-size: 1.25rem;
  }
}

.fw-20 {
  font-weight: 200 !important;
}

.fs-22 {
  font-size: 1.375rem;
}

@media screen and (min-width: 768px) {
  .fs-md-22 {
    font-size: 1.375rem;
  }
}

.fw-22 {
  font-weight: 220 !important;
}

.fs-25 {
  font-size: 1.5625rem;
}

@media screen and (min-width: 768px) {
  .fs-md-25 {
    font-size: 1.5625rem;
  }
}

.fw-25 {
  font-weight: 250 !important;
}

.fs-28 {
  font-size: 1.75rem;
}

@media screen and (min-width: 768px) {
  .fs-md-28 {
    font-size: 1.75rem;
  }
}

.fw-28 {
  font-weight: 280 !important;
}

.fs-30 {
  font-size: 1.875rem;
}

@media screen and (min-width: 768px) {
  .fs-md-30 {
    font-size: 1.875rem;
  }
}

.fw-30 {
  font-weight: 300 !important;
}

.fs-32 {
  font-size: 2rem;
}

@media screen and (min-width: 768px) {
  .fs-md-32 {
    font-size: 2rem;
  }
}

.fw-32 {
  font-weight: 320 !important;
}

.fs-35 {
  font-size: 2.1875rem;
}

@media screen and (min-width: 768px) {
  .fs-md-35 {
    font-size: 2.1875rem;
  }
}

.fw-35 {
  font-weight: 350 !important;
}

.fs-40 {
  font-size: 2.5rem;
}

@media screen and (min-width: 768px) {
  .fs-md-40 {
    font-size: 2.5rem;
  }
}

.fw-40 {
  font-weight: 400 !important;
}

.fs-50 {
  font-size: 3.125rem;
}

@media screen and (min-width: 768px) {
  .fs-md-50 {
    font-size: 3.125rem;
  }
}

.fw-50 {
  font-weight: 500 !important;
}

.fs-70 {
  font-size: 4.375rem;
}

@media screen and (min-width: 768px) {
  .fs-md-70 {
    font-size: 4.375rem;
  }
}

.fw-70 {
  font-weight: 700 !important;
}

.fs-80 {
  font-size: 5rem;
}

@media screen and (min-width: 768px) {
  .fs-md-80 {
    font-size: 5rem;
  }
}

.fw-80 {
  font-weight: 800 !important;
}

.m-top-0 {
  margin-top: 0rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-0 {
    margin-top: 0rem !important;
  }
}
.p-top-0 {
  padding-top: 0rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-0 {
    padding-top: 0rem !important;
  }
}
.m-bottom-0 {
  margin-bottom: 0rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-0 {
    margin-bottom: 0rem !important;
  }
}
.p-bottom-0 {
  padding-bottom: 0rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-0 {
    padding-bottom: 0rem !important;
  }
}
.m-left-0 {
  margin-left: 0rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-0 {
    margin-left: 0rem !important;
  }
}
.p-left-0 {
  padding-left: 0rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-0 {
    padding-left: 0rem !important;
  }
}
.m-right-0 {
  margin-right: 0rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-0 {
    margin-right: 0rem !important;
  }
}
.p-right-0 {
  padding-right: 0rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-0 {
    padding-right: 0rem !important;
  }
}
.m-top-5 {
  margin-top: 0.3125rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-5 {
    margin-top: 0.3125rem !important;
  }
}
.p-top-5 {
  padding-top: 0.3125rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-5 {
    padding-top: 0.3125rem !important;
  }
}
.m-bottom-5 {
  margin-bottom: 0.3125rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-5 {
    margin-bottom: 0.3125rem !important;
  }
}
.p-bottom-5 {
  padding-bottom: 0.3125rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-5 {
    padding-bottom: 0.3125rem !important;
  }
}
.m-left-5 {
  margin-left: 0.3125rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-5 {
    margin-left: 0.3125rem !important;
  }
}
.p-left-5 {
  padding-left: 0.3125rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-5 {
    padding-left: 0.3125rem !important;
  }
}
.m-right-5 {
  margin-right: 0.3125rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-5 {
    margin-right: 0.3125rem !important;
  }
}
.p-right-5 {
  padding-right: 0.3125rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-5 {
    padding-right: 0.3125rem !important;
  }
}
.m-top-10 {
  margin-top: 0.625rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-10 {
    margin-top: 0.625rem !important;
  }
}
.p-top-10 {
  padding-top: 0.625rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-10 {
    padding-top: 0.625rem !important;
  }
}
.m-bottom-10 {
  margin-bottom: 0.625rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-10 {
    margin-bottom: 0.625rem !important;
  }
}
.p-bottom-10 {
  padding-bottom: 0.625rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-10 {
    padding-bottom: 0.625rem !important;
  }
}
.m-left-10 {
  margin-left: 0.625rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-10 {
    margin-left: 0.625rem !important;
  }
}
.p-left-10 {
  padding-left: 0.625rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-10 {
    padding-left: 0.625rem !important;
  }
}
.m-right-10 {
  margin-right: 0.625rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-10 {
    margin-right: 0.625rem !important;
  }
}
.p-right-10 {
  padding-right: 0.625rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-10 {
    padding-right: 0.625rem !important;
  }
}
.m-top-12 {
  margin-top: 0.75rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-12 {
    margin-top: 0.75rem !important;
  }
}
.p-top-12 {
  padding-top: 0.75rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-12 {
    padding-top: 0.75rem !important;
  }
}
.m-bottom-12 {
  margin-bottom: 0.75rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-12 {
    margin-bottom: 0.75rem !important;
  }
}
.p-bottom-12 {
  padding-bottom: 0.75rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-12 {
    padding-bottom: 0.75rem !important;
  }
}
.m-left-12 {
  margin-left: 0.75rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-12 {
    margin-left: 0.75rem !important;
  }
}
.p-left-12 {
  padding-left: 0.75rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-12 {
    padding-left: 0.75rem !important;
  }
}
.m-right-12 {
  margin-right: 0.75rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-12 {
    margin-right: 0.75rem !important;
  }
}
.p-right-12 {
  padding-right: 0.75rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-12 {
    padding-right: 0.75rem !important;
  }
}
.m-top-15 {
  margin-top: 0.9375rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-15 {
    margin-top: 0.9375rem !important;
  }
}
.p-top-15 {
  padding-top: 0.9375rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-15 {
    padding-top: 0.9375rem !important;
  }
}
.m-bottom-15 {
  margin-bottom: 0.9375rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-15 {
    margin-bottom: 0.9375rem !important;
  }
}
.p-bottom-15 {
  padding-bottom: 0.9375rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-15 {
    padding-bottom: 0.9375rem !important;
  }
}
.m-left-15 {
  margin-left: 0.9375rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-15 {
    margin-left: 0.9375rem !important;
  }
}
.p-left-15 {
  padding-left: 0.9375rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-15 {
    padding-left: 0.9375rem !important;
  }
}
.m-right-15 {
  margin-right: 0.9375rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-15 {
    margin-right: 0.9375rem !important;
  }
}
.p-right-15 {
  padding-right: 0.9375rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-15 {
    padding-right: 0.9375rem !important;
  }
}
.m-top-20 {
  margin-top: 1.25rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-20 {
    margin-top: 1.25rem !important;
  }
}
.p-top-20 {
  padding-top: 1.25rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-20 {
    padding-top: 1.25rem !important;
  }
}
.m-bottom-20 {
  margin-bottom: 1.25rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-20 {
    margin-bottom: 1.25rem !important;
  }
}
.p-bottom-20 {
  padding-bottom: 1.25rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-20 {
    padding-bottom: 1.25rem !important;
  }
}
.m-left-20 {
  margin-left: 1.25rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-20 {
    margin-left: 1.25rem !important;
  }
}
.p-left-20 {
  padding-left: 1.25rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-20 {
    padding-left: 1.25rem !important;
  }
}
.m-right-20 {
  margin-right: 1.25rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-20 {
    margin-right: 1.25rem !important;
  }
}
.p-right-20 {
  padding-right: 1.25rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-20 {
    padding-right: 1.25rem !important;
  }
}
.m-top-25 {
  margin-top: 1.5625rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-25 {
    margin-top: 1.5625rem !important;
  }
}
.p-top-25 {
  padding-top: 1.5625rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-25 {
    padding-top: 1.5625rem !important;
  }
}
.m-bottom-25 {
  margin-bottom: 1.5625rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-25 {
    margin-bottom: 1.5625rem !important;
  }
}
.p-bottom-25 {
  padding-bottom: 1.5625rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-25 {
    padding-bottom: 1.5625rem !important;
  }
}
.m-left-25 {
  margin-left: 1.5625rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-25 {
    margin-left: 1.5625rem !important;
  }
}
.p-left-25 {
  padding-left: 1.5625rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-25 {
    padding-left: 1.5625rem !important;
  }
}
.m-right-25 {
  margin-right: 1.5625rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-25 {
    margin-right: 1.5625rem !important;
  }
}
.p-right-25 {
  padding-right: 1.5625rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-25 {
    padding-right: 1.5625rem !important;
  }
}
.m-top-30 {
  margin-top: 1.875rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-30 {
    margin-top: 1.875rem !important;
  }
}
.p-top-30 {
  padding-top: 1.875rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-30 {
    padding-top: 1.875rem !important;
  }
}
.m-bottom-30 {
  margin-bottom: 1.875rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-30 {
    margin-bottom: 1.875rem !important;
  }
}
.p-bottom-30 {
  padding-bottom: 1.875rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-30 {
    padding-bottom: 1.875rem !important;
  }
}
.m-left-30 {
  margin-left: 1.875rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-30 {
    margin-left: 1.875rem !important;
  }
}
.p-left-30 {
  padding-left: 1.875rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-30 {
    padding-left: 1.875rem !important;
  }
}
.m-right-30 {
  margin-right: 1.875rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-30 {
    margin-right: 1.875rem !important;
  }
}
.p-right-30 {
  padding-right: 1.875rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-30 {
    padding-right: 1.875rem !important;
  }
}
.m-top-35 {
  margin-top: 2.1875rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-35 {
    margin-top: 2.1875rem !important;
  }
}
.p-top-35 {
  padding-top: 2.1875rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-35 {
    padding-top: 2.1875rem !important;
  }
}
.m-bottom-35 {
  margin-bottom: 2.1875rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-35 {
    margin-bottom: 2.1875rem !important;
  }
}
.p-bottom-35 {
  padding-bottom: 2.1875rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-35 {
    padding-bottom: 2.1875rem !important;
  }
}
.m-left-35 {
  margin-left: 2.1875rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-35 {
    margin-left: 2.1875rem !important;
  }
}
.p-left-35 {
  padding-left: 2.1875rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-35 {
    padding-left: 2.1875rem !important;
  }
}
.m-right-35 {
  margin-right: 2.1875rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-35 {
    margin-right: 2.1875rem !important;
  }
}
.p-right-35 {
  padding-right: 2.1875rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-35 {
    padding-right: 2.1875rem !important;
  }
}
.m-top-40 {
  margin-top: 2.5rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-40 {
    margin-top: 2.5rem !important;
  }
}
.p-top-40 {
  padding-top: 2.5rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-40 {
    padding-top: 2.5rem !important;
  }
}
.m-bottom-40 {
  margin-bottom: 2.5rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-40 {
    margin-bottom: 2.5rem !important;
  }
}
.p-bottom-40 {
  padding-bottom: 2.5rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-40 {
    padding-bottom: 2.5rem !important;
  }
}
.m-left-40 {
  margin-left: 2.5rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-40 {
    margin-left: 2.5rem !important;
  }
}
.p-left-40 {
  padding-left: 2.5rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-40 {
    padding-left: 2.5rem !important;
  }
}
.m-right-40 {
  margin-right: 2.5rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-40 {
    margin-right: 2.5rem !important;
  }
}
.p-right-40 {
  padding-right: 2.5rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-40 {
    padding-right: 2.5rem !important;
  }
}
.m-top-45 {
  margin-top: 2.8125rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-45 {
    margin-top: 2.8125rem !important;
  }
}
.p-top-45 {
  padding-top: 2.8125rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-45 {
    padding-top: 2.8125rem !important;
  }
}
.m-bottom-45 {
  margin-bottom: 2.8125rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-45 {
    margin-bottom: 2.8125rem !important;
  }
}
.p-bottom-45 {
  padding-bottom: 2.8125rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-45 {
    padding-bottom: 2.8125rem !important;
  }
}
.m-left-45 {
  margin-left: 2.8125rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-45 {
    margin-left: 2.8125rem !important;
  }
}
.p-left-45 {
  padding-left: 2.8125rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-45 {
    padding-left: 2.8125rem !important;
  }
}
.m-right-45 {
  margin-right: 2.8125rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-45 {
    margin-right: 2.8125rem !important;
  }
}
.p-right-45 {
  padding-right: 2.8125rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-45 {
    padding-right: 2.8125rem !important;
  }
}
.m-top-50 {
  margin-top: 3.125rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-50 {
    margin-top: 3.125rem !important;
  }
}
.p-top-50 {
  padding-top: 3.125rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-50 {
    padding-top: 3.125rem !important;
  }
}
.m-bottom-50 {
  margin-bottom: 3.125rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-50 {
    margin-bottom: 3.125rem !important;
  }
}
.p-bottom-50 {
  padding-bottom: 3.125rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-50 {
    padding-bottom: 3.125rem !important;
  }
}
.m-left-50 {
  margin-left: 3.125rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-50 {
    margin-left: 3.125rem !important;
  }
}
.p-left-50 {
  padding-left: 3.125rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-50 {
    padding-left: 3.125rem !important;
  }
}
.m-right-50 {
  margin-right: 3.125rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-50 {
    margin-right: 3.125rem !important;
  }
}
.p-right-50 {
  padding-right: 3.125rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-50 {
    padding-right: 3.125rem !important;
  }
}
.m-top-55 {
  margin-top: 3.4375rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-55 {
    margin-top: 3.4375rem !important;
  }
}
.p-top-55 {
  padding-top: 3.4375rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-55 {
    padding-top: 3.4375rem !important;
  }
}
.m-bottom-55 {
  margin-bottom: 3.4375rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-55 {
    margin-bottom: 3.4375rem !important;
  }
}
.p-bottom-55 {
  padding-bottom: 3.4375rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-55 {
    padding-bottom: 3.4375rem !important;
  }
}
.m-left-55 {
  margin-left: 3.4375rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-55 {
    margin-left: 3.4375rem !important;
  }
}
.p-left-55 {
  padding-left: 3.4375rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-55 {
    padding-left: 3.4375rem !important;
  }
}
.m-right-55 {
  margin-right: 3.4375rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-55 {
    margin-right: 3.4375rem !important;
  }
}
.p-right-55 {
  padding-right: 3.4375rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-55 {
    padding-right: 3.4375rem !important;
  }
}
.m-top-60 {
  margin-top: 3.75rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-60 {
    margin-top: 3.75rem !important;
  }
}
.p-top-60 {
  padding-top: 3.75rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-60 {
    padding-top: 3.75rem !important;
  }
}
.m-bottom-60 {
  margin-bottom: 3.75rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-60 {
    margin-bottom: 3.75rem !important;
  }
}
.p-bottom-60 {
  padding-bottom: 3.75rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-60 {
    padding-bottom: 3.75rem !important;
  }
}
.m-left-60 {
  margin-left: 3.75rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-60 {
    margin-left: 3.75rem !important;
  }
}
.p-left-60 {
  padding-left: 3.75rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-60 {
    padding-left: 3.75rem !important;
  }
}
.m-right-60 {
  margin-right: 3.75rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-60 {
    margin-right: 3.75rem !important;
  }
}
.p-right-60 {
  padding-right: 3.75rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-60 {
    padding-right: 3.75rem !important;
  }
}
.m-top-65 {
  margin-top: 4.0625rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-65 {
    margin-top: 4.0625rem !important;
  }
}
.p-top-65 {
  padding-top: 4.0625rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-65 {
    padding-top: 4.0625rem !important;
  }
}
.m-bottom-65 {
  margin-bottom: 4.0625rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-65 {
    margin-bottom: 4.0625rem !important;
  }
}
.p-bottom-65 {
  padding-bottom: 4.0625rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-65 {
    padding-bottom: 4.0625rem !important;
  }
}
.m-left-65 {
  margin-left: 4.0625rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-65 {
    margin-left: 4.0625rem !important;
  }
}
.p-left-65 {
  padding-left: 4.0625rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-65 {
    padding-left: 4.0625rem !important;
  }
}
.m-right-65 {
  margin-right: 4.0625rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-65 {
    margin-right: 4.0625rem !important;
  }
}
.p-right-65 {
  padding-right: 4.0625rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-65 {
    padding-right: 4.0625rem !important;
  }
}
.m-top-70 {
  margin-top: 4.375rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-70 {
    margin-top: 4.375rem !important;
  }
}
.p-top-70 {
  padding-top: 4.375rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-70 {
    padding-top: 4.375rem !important;
  }
}
.m-bottom-70 {
  margin-bottom: 4.375rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-70 {
    margin-bottom: 4.375rem !important;
  }
}
.p-bottom-70 {
  padding-bottom: 4.375rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-70 {
    padding-bottom: 4.375rem !important;
  }
}
.m-left-70 {
  margin-left: 4.375rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-70 {
    margin-left: 4.375rem !important;
  }
}
.p-left-70 {
  padding-left: 4.375rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-70 {
    padding-left: 4.375rem !important;
  }
}
.m-right-70 {
  margin-right: 4.375rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-70 {
    margin-right: 4.375rem !important;
  }
}
.p-right-70 {
  padding-right: 4.375rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-70 {
    padding-right: 4.375rem !important;
  }
}
.m-top-80 {
  margin-top: 5rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-80 {
    margin-top: 5rem !important;
  }
}
.p-top-80 {
  padding-top: 5rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-80 {
    padding-top: 5rem !important;
  }
}
.m-bottom-80 {
  margin-bottom: 5rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-80 {
    margin-bottom: 5rem !important;
  }
}
.p-bottom-80 {
  padding-bottom: 5rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-80 {
    padding-bottom: 5rem !important;
  }
}
.m-left-80 {
  margin-left: 5rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-80 {
    margin-left: 5rem !important;
  }
}
.p-left-80 {
  padding-left: 5rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-80 {
    padding-left: 5rem !important;
  }
}
.m-right-80 {
  margin-right: 5rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-80 {
    margin-right: 5rem !important;
  }
}
.p-right-80 {
  padding-right: 5rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-80 {
    padding-right: 5rem !important;
  }
}
.m-top-90 {
  margin-top: 5.625rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-90 {
    margin-top: 5.625rem !important;
  }
}
.p-top-90 {
  padding-top: 5.625rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-90 {
    padding-top: 5.625rem !important;
  }
}
.m-bottom-90 {
  margin-bottom: 5.625rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-90 {
    margin-bottom: 5.625rem !important;
  }
}
.p-bottom-90 {
  padding-bottom: 5.625rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-90 {
    padding-bottom: 5.625rem !important;
  }
}
.m-left-90 {
  margin-left: 5.625rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-90 {
    margin-left: 5.625rem !important;
  }
}
.p-left-90 {
  padding-left: 5.625rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-90 {
    padding-left: 5.625rem !important;
  }
}
.m-right-90 {
  margin-right: 5.625rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-90 {
    margin-right: 5.625rem !important;
  }
}
.p-right-90 {
  padding-right: 5.625rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-90 {
    padding-right: 5.625rem !important;
  }
}
.m-top-100 {
  margin-top: 6.25rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-100 {
    margin-top: 6.25rem !important;
  }
}
.p-top-100 {
  padding-top: 6.25rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-100 {
    padding-top: 6.25rem !important;
  }
}
.m-bottom-100 {
  margin-bottom: 6.25rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-100 {
    margin-bottom: 6.25rem !important;
  }
}
.p-bottom-100 {
  padding-bottom: 6.25rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-100 {
    padding-bottom: 6.25rem !important;
  }
}
.m-left-100 {
  margin-left: 6.25rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-100 {
    margin-left: 6.25rem !important;
  }
}
.p-left-100 {
  padding-left: 6.25rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-100 {
    padding-left: 6.25rem !important;
  }
}
.m-right-100 {
  margin-right: 6.25rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-100 {
    margin-right: 6.25rem !important;
  }
}
.p-right-100 {
  padding-right: 6.25rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-100 {
    padding-right: 6.25rem !important;
  }
}
.m-top-105 {
  margin-top: 6.5625rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-105 {
    margin-top: 6.5625rem !important;
  }
}
.p-top-105 {
  padding-top: 6.5625rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-105 {
    padding-top: 6.5625rem !important;
  }
}
.m-bottom-105 {
  margin-bottom: 6.5625rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-105 {
    margin-bottom: 6.5625rem !important;
  }
}
.p-bottom-105 {
  padding-bottom: 6.5625rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-105 {
    padding-bottom: 6.5625rem !important;
  }
}
.m-left-105 {
  margin-left: 6.5625rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-105 {
    margin-left: 6.5625rem !important;
  }
}
.p-left-105 {
  padding-left: 6.5625rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-105 {
    padding-left: 6.5625rem !important;
  }
}
.m-right-105 {
  margin-right: 6.5625rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-105 {
    margin-right: 6.5625rem !important;
  }
}
.p-right-105 {
  padding-right: 6.5625rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-105 {
    padding-right: 6.5625rem !important;
  }
}
.m-top-110 {
  margin-top: 6.875rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-110 {
    margin-top: 6.875rem !important;
  }
}
.p-top-110 {
  padding-top: 6.875rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-110 {
    padding-top: 6.875rem !important;
  }
}
.m-bottom-110 {
  margin-bottom: 6.875rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-110 {
    margin-bottom: 6.875rem !important;
  }
}
.p-bottom-110 {
  padding-bottom: 6.875rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-110 {
    padding-bottom: 6.875rem !important;
  }
}
.m-left-110 {
  margin-left: 6.875rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-110 {
    margin-left: 6.875rem !important;
  }
}
.p-left-110 {
  padding-left: 6.875rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-110 {
    padding-left: 6.875rem !important;
  }
}
.m-right-110 {
  margin-right: 6.875rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-110 {
    margin-right: 6.875rem !important;
  }
}
.p-right-110 {
  padding-right: 6.875rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-110 {
    padding-right: 6.875rem !important;
  }
}
.m-top-120 {
  margin-top: 7.5rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-120 {
    margin-top: 7.5rem !important;
  }
}
.p-top-120 {
  padding-top: 7.5rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-120 {
    padding-top: 7.5rem !important;
  }
}
.m-bottom-120 {
  margin-bottom: 7.5rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-120 {
    margin-bottom: 7.5rem !important;
  }
}
.p-bottom-120 {
  padding-bottom: 7.5rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-120 {
    padding-bottom: 7.5rem !important;
  }
}
.m-left-120 {
  margin-left: 7.5rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-120 {
    margin-left: 7.5rem !important;
  }
}
.p-left-120 {
  padding-left: 7.5rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-120 {
    padding-left: 7.5rem !important;
  }
}
.m-right-120 {
  margin-right: 7.5rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-120 {
    margin-right: 7.5rem !important;
  }
}
.p-right-120 {
  padding-right: 7.5rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-120 {
    padding-right: 7.5rem !important;
  }
}
.m-top-130 {
  margin-top: 8.125rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-130 {
    margin-top: 8.125rem !important;
  }
}
.p-top-130 {
  padding-top: 8.125rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-130 {
    padding-top: 8.125rem !important;
  }
}
.m-bottom-130 {
  margin-bottom: 8.125rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-130 {
    margin-bottom: 8.125rem !important;
  }
}
.p-bottom-130 {
  padding-bottom: 8.125rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-130 {
    padding-bottom: 8.125rem !important;
  }
}
.m-left-130 {
  margin-left: 8.125rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-130 {
    margin-left: 8.125rem !important;
  }
}
.p-left-130 {
  padding-left: 8.125rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-130 {
    padding-left: 8.125rem !important;
  }
}
.m-right-130 {
  margin-right: 8.125rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-130 {
    margin-right: 8.125rem !important;
  }
}
.p-right-130 {
  padding-right: 8.125rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-130 {
    padding-right: 8.125rem !important;
  }
}
.m-top-150 {
  margin-top: 9.375rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-150 {
    margin-top: 9.375rem !important;
  }
}
.p-top-150 {
  padding-top: 9.375rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-150 {
    padding-top: 9.375rem !important;
  }
}
.m-bottom-150 {
  margin-bottom: 9.375rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-150 {
    margin-bottom: 9.375rem !important;
  }
}
.p-bottom-150 {
  padding-bottom: 9.375rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-150 {
    padding-bottom: 9.375rem !important;
  }
}
.m-left-150 {
  margin-left: 9.375rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-150 {
    margin-left: 9.375rem !important;
  }
}
.p-left-150 {
  padding-left: 9.375rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-150 {
    padding-left: 9.375rem !important;
  }
}
.m-right-150 {
  margin-right: 9.375rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-150 {
    margin-right: 9.375rem !important;
  }
}
.p-right-150 {
  padding-right: 9.375rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-150 {
    padding-right: 9.375rem !important;
  }
}
.m-top-300 {
  margin-top: 18.75rem;
}

@media screen and (min-width: 768px) {
  .m-top-md-300 {
    margin-top: 18.75rem !important;
  }
}
.p-top-300 {
  padding-top: 18.75rem !important;
}

@media screen and (min-width: 768px) {
  .p-top-md-300 {
    padding-top: 18.75rem !important;
  }
}
.m-bottom-300 {
  margin-bottom: 18.75rem;
}

@media screen and (min-width: 768px) {
  .m-bottom-md-300 {
    margin-bottom: 18.75rem !important;
  }
}
.p-bottom-300 {
  padding-bottom: 18.75rem !important;
}

@media screen and (min-width: 768px) {
  .p-bottom-md-300 {
    padding-bottom: 18.75rem !important;
  }
}
.m-left-300 {
  margin-left: 18.75rem;
}

@media screen and (min-width: 768px) {
  .m-left-md-300 {
    margin-left: 18.75rem !important;
  }
}
.p-left-300 {
  padding-left: 18.75rem !important;
}

@media screen and (min-width: 768px) {
  .p-left-md-300 {
    padding-left: 18.75rem !important;
  }
}
.m-right-300 {
  margin-right: 18.75rem;
}

@media screen and (min-width: 768px) {
  .m-right-md-300 {
    margin-right: 18.75rem !important;
  }
}
.p-right-300 {
  padding-right: 18.75rem !important;
}

@media screen and (min-width: 768px) {
  .p-right-md-300 {
    padding-right: 18.75rem !important;
  }
}
.btn {
  font-family: "poppins_medium", sans-serif;
}

.btn-login, .btn-primary {
  background: #2F2DAA;
  font-family: "poppins_semibold", sans-serif;
}

.page-item.active .page-link {
  background: #2F2DAA;
  font-family: "poppins_light", sans-serif;
}

.form-control,
.datagrid td,
.datagrid th,
.user-menu-wrapper .user-details .user-name,
.datalist dt, .datalist dd,
.page-item .page-link,
.filter-heading a,
.form-widget .form-select {
  font-family: "poppins_light", sans-serif;
}

.btn-login:hover {
  background: #2F2DAA;
}

.text-header {
  color: #2F2DAA;
}

.content-header-title .title {
  font-family: "poppins_medium", sans-serif;
  color: #2F2F2F;
}

.form-group label, .datagrid thead a {
  color: #2F2F2F;
  font-family: "poppins_semibold", sans-serif;
}

.sidebar {
  background: #2F2F2F;
}

#main-menu .menu .menu-item-contents,
#main-menu .menu .menu-icon,
#main-menu .menu .submenu a {
  font-family: "poppins_light", sans-serif;
  color: #FFFFFF;
}

#main-menu .menu .menu-item.active {
  background: #8C9AE1;
}

#main-menu .menu .menu-item.active .menu-item-label,
#main-menu .menu .menu-item.active:not(.expanded) .menu-icon {
  color: #2F2F2F;
}